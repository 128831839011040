<template>
    <v-form ref="form">
    <v-dialog v-model="dialog" max-width="600">
        <template v-slot:activator="{on,attrs}">
            <v-btn
                v-if="currentItem.id===null" 
                v-bind="attrs"
                v-on="on"
                outlined
                height="80"
                block
                class="custom-btn"
            >   Bio marcador
                <v-icon small class="ma-3" color="white">mdi-plus-outline</v-icon>
            </v-btn>
            
            <v-btn
                v-else 
                v-bind="attrs"
                v-on="on"
                icon
                class="custom-btn"
            > 
                <v-icon small class="ma-3" color="white">mdi-bullhorn-variant-outline</v-icon>
            </v-btn>
        </template>
        <v-card class="pa-4 ma-0">
            <v-row dense>
                <v-col class="text-right">
                    <v-icon @click="dialog=false">mdi-close</v-icon>
                </v-col>
            </v-row>
            <v-card-title>
                BIO MARCADOR / TRIAGEM GLICEMIA
            </v-card-title>
            <v-card-text>
                <p class="text-caption">{{ currentItem.nome }}</p>
                <v-card class="pa-2 ma-0">
                    <v-card-title>RESULTADO</v-card-title>
                    <v-row dense>
                        <v-col dense :cols="12">
                            <v-text-field dense required :rules="[e=>!!e||'Campo obrigatório!']" v-model="currentItem.glicemia_capilar" label="Glicemia Capilar mg/dl"></v-text-field>
                        </v-col>
                        <v-col dense :cols="12">
                            <v-text-field dense required :rules="[e=>!!e||'Campo obrigatório!']" v-model="currentItem.glicemia_plasmatica" label="Glicemia Plasmática mg/dl"></v-text-field>
                        </v-col>
                        <v-col dense :cols="12">
                            <v-text-field dense type="number" step="any" required :rules="[e=>!!e||'Campo obrigatório!']" v-model="currentItem.hemoglobina_glicada" label="Hemoglobina Glicada mg/dl"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card>
            </v-card-text>
            <v-card-actions>
                <v-row dense>
                    <v-col dense :cols="12">
                        <v-btn :loading="loading" outlined block @click="post_lista()"><v-icon>mdi-content-save</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
            <v-row min-height="20">
                <v-col dense :cols="12" class="text-center"> <span v-html="msg"></span></v-col>
            </v-row>
        </v-card>
        </v-dialog>
    </v-form>
</template>
<script>
    import httpApiService from '@/http'
    export default{
        name:'Biomarcador',
        props:{currentItem:{type:Object}},
        data:()=>({
            dialog:false,
            loading:false,
            msg:'',
            sexos:[],
            orgaos_expedidores:[],
            estados:[],
            frequencia_hemoglobina_glicada_diaria:[],
            cidades:[],
            problema_pes:[],
        }),
        methods:{
            get_listas(url, objeto){
                this.loading = true
                httpApiService.get(url)
                .then(res=>{
                    this[objeto]=res.data
                })
                .finally(()=>{this.loading = false})
            },
         
            async post_lista(){
                this.loading = true
                if(!this.$refs.form.validate()) {
                    this.loading=false 
                    return false
                }

                const response = await httpApiService.patch(`mutirao-sus/paciente/${this.currentItem.id}/`,this.currentItem)
                
                if(response.status===200){
                    this.msg = `<p class="green--text">Paciente ${response.data.id} - ${response.data.nome} Gravado com sucesso!</p>`
                    this.$emit('atualiza')
                }else{
                    for(const i in response.response.data){
                        this.msg += `<p class="red--text">${response.response.data[i]}</p>`
                    }    
                }
                this.loading=false
                
            },
            
        },
        mounted(){
        },
       
    }
</script>
<style>

.custom-btn {
    color: #fff !important;
    background-color: #305f67 !important; /* Cor de fundo desejada em hexadecimal */
    border-radius: 8px !important; /* Raio das bordas, ajuste conforme necessário */
    border-color: #fff !important;
  }
</style>